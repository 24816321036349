<template>
  <div
    class="flex-shrink-0 w-24 h-24 overflow-hidden bg-center bg-cover rounded-full"
    :style="`background-image: url('${picture}');`"
  ></div>
</template>

<script>
export default {
  name: 'CircularImageComponent',
  props: {
    picture: {
      type: String,
      require: true,
    },
  },
};
</script>
