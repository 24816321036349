<template>
    <interior-page-content-container
      nav-selection=""
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      :modal-show="modal.show"
      :showRightControls="true"
      :notificationMessage.sync="notificationMessage"
      notificationType="alert"
    >
      <template #modal>
        <modal-confirmation
          title="Discard Data?"
          description="Are you sure want to go back? All your unsaved data will be lost"
          text-confirm="Discard"
          text-cancel="Cancel"
          @cancel="modalCancel"
          @confirm="modalConfirm"
        />
      </template>

      <template #title>
        Create Room
      </template>

      <template #content>
        <div class="`
          flex flex-col items-stretch self-center h-full pt-10
          w-5/6 phone:w-3/4 md:w-2/3 lg:w-3/5 max-w-md
          mb-10 pb-10
        `">

          <!-- Image and name -->
          <div class="flex items-center flex-shrink-0 w-full mb-4">
            <circular-image-component
              :picture="picture"
              class="flex-shrink-0"
            />
            <div class="flex flex-col h-16 pl-3 text-left">
              <p class="text-2xl font-extrabold text-primary">
                New Room
              </p>
              <p class="text-sm text-content">
                *Landscape Pictures will display best
              </p>
            </div>
          </div>

          <!-- Room Info -->
          <form @submit.prevent="createRoom" class="w-full p-3 mb-4 rounded-lg bg-backfill">
            <text-input-component
              :value.sync="room.name"
              :valid="validateInputs ? roomValidity.nameValid : true"
              label="Room Name"
              mode="form"
            />
            <text-input-component
              :value.sync="room.floor"
              :valid="validateInputs ? roomValidity.floorValid : true"
              label="Floor"
              mode="form"
            />
            <button class="hidden"/>
          </form>

          <!-- Actions -->
          <div class="w-full p-3 mb-4 rounded-lg bg-backfill">
            <button-inline
              @click="takePicture"
              label="Update"
              value="Add Photo"
              mode="edit"
              :disabled="false"
            />
            <input
              @change="replacePicture" v-show='false'
              type="file" accept="image/*"
            />
          </div>

          <button-large
            @click="createRoom()"
            color="green"
            class="self-center"
            :disabled="createRoomExecuted"
          >
            Create Room
          </button-large>
          <div class="h-10 opacity-0">space...</div>

          <!-- <p class="pb-10 text-left text-content">
            Our decluttering application has three layers; Rooms, Rooms and Items.
            After creating a room you can create your digital items.
          </p> -->

        </div>
      </template>
    </interior-page-content-container>
</template>

<script>

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import ModalConfirmation from '@/components/shared/inputs/ModalConfirmation.vue';
import CircularImageComponent from '@/components/shared/general/CircularImageComponent.vue';
import TextInputComponent from '@/components/shared/inputs/TextInputComponent.vue';
import ButtonInline from '@/components/shared/Buttons/ButtonInline.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

import {
  ROOM_POST,
} from '@/store/actions';

export default {
  name: 'RoomCreate',
  components: {
    InteriorPageContentContainer,
    ModalConfirmation,
    CircularImageComponent,
    TextInputComponent,
    ButtonInline,
    ButtonLarge,
  },
  data() {
    return {
      room: {
        name: '',
        floor: '',
        picture: null,
      },
      roomValidity: {
        nameValid: false,
        floorValid: false,
      },
      modal: {
        show: false,
      },
      validateInputs: false,
      edited: false,
      actionComplete: false,
      to: this.defaultBackRoute,
      createRoomExecuted: false,
      notificationMessage: '',
    };
  },
  computed: {
    defaultBackRoute() {
      return {
        name: 'Project',
        params: {
          project_id: this.$route.params.project_id,
          tab: 'declutter',
        },
      };
    },
    picture() {
      return this.room.picture
        ? this.pictureUrl(this.room.picture)
        : '/img/default-room-images/room.jpg';
    },
    valid() {
      return this.roomValidity.nameValid && this.roomValidity.floorValid;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.edited && !this.actionComplete) {
      this.to = to;
      this.modal.show = true;
    } else next();
  },
  watch: {
    room: {
      handler(newVal) {
        this.roomValidity.nameValid = newVal.name.length > 0;
        this.roomValidity.floorValid = newVal.floor.length > 0;

        if (this.room.name !== '') this.edited = true;
        else if (this.room.floor !== '') this.edited = true;
        else this.edited = false;
      },
      deep: true,
    },
  },
  methods: {
    async createRoom() {
      this.validateInputs = true;
      if (!this.valid) return;
      this.createRoomExecuted = true;
      this.edited = false;
      this.$store.dispatch(ROOM_POST, {
        room: {
          name: this.room.name,
          floor: this.room.floor,
          picture: await this.getPictureFile(),
        },
        projectId: this.$route.params.project_id,
      })
        .then((newRoom) => {
          this.actionComplete = true;
          this.$router.push({
            name: 'Room',
            params: {
              room_id: newRoom.id,
            },
          });
        })
        .catch((error) => {
          this.notificationMessage = error.response.data;
          this.createRoomExecuted = false;
        });
    },
    modalConfirm() {
      this.actionComplete = true;
      this.$router.push(this.to);
    },
    modalCancel() {
      this.modal.show = false;
      this.to = this.defaultBackRoute;
    },
    backNav() {
      this.$router.push(this.defaultBackRoute);
    },

    async getPictureFile() {
      if (this.room.picture) return this.room.picture;
      return fetch(this.picture)
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => new File([blob], 'default-room-image.jpeg'));
    },
    pictureUrl(obj) {
      return typeof obj === typeof '' ? obj : URL.createObjectURL(obj);
    },
    replacePicture(event) {
      this.room = {
        ...this.room,
        picture: event.target.files[0],
      };
    },
    takePicture() {
      document.querySelector('input[type="file"]').click();
    },
  },

};
</script>
